* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6em;
  margin: 0;
  outline: none;
  user-select: none;
  font-family: FiraSans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  width: 1rem;
}

*::-webkit-scrollbar-track {
  background: #e3e5f7;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7cadf;
  border-radius: 1rem;
}

input {
  font-family: FiraSans, sans-serif;
}

@font-face {
  font-family: 'Open-Sans';
  src: url(./assets/fonts/open-sans/OpenSans-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open-Sans-Bold';
  src: url(./assets/fonts/open-sans/OpenSans-Bold.ttf) format('truetype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Merriweather';
  src: url(./assets/fonts/merriweather/Merriweather-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather-Bold';
  src: url(./assets/fonts/merriweather/Merriweather-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FiraSans-Light';
  src: url(./assets/fonts/fira-sans/FiraSans-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FiraSans';
  src: url(./assets/fonts/fira-sans/FiraSans-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FiraSans-Medium';
  src: url(./assets/fonts/fira-sans/FiraSans-Medium.ttf) format('truetype');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'FiraSans-Bold';
  src: url(./assets/fonts/fira-sans/FiraSans-Bold.ttf) format('truetype');
  font-display: swap;
  font-weight: 700;
}